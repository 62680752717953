.navigation {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color:whitesmoke;
    color: black;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  }
  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
  .navigation-menu {
    margin-left: auto;
    float:left;
    
  }
 
  .navigation-menu ul {
    display: flex;
    padding: 0;
    margin-left: -100px;
  
 }
  .navigation-menu li {
/* removes default disc bullet for li tags and applies margin to left & right side
*/    
  list-style-type: none;
    /*margin: 0 1rem;*/
    margin-left: -300px;
    font-weight: bold;
 }
  
  .navigation-menu li a {
/*increases the surface area of the anchor tag to span more than just the anchor text
*/    
 text-decoration: none;
    display: block;
    width: 100%;
    color:black;
  }
  .navigation-menu li :hover{
    background-color: rgb(0, 0, 0);
    text-decoration: underline;
  }
  .hamburger {
    /* removes default border on button element
    */
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color:white;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  /* positions the icon to the right and center aligns it vertically
    */
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .hamburger:hover {
    background-color: #2642af;
  }
  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
      
    }
    
  }

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }
}
@media screen and (max-width: 768px) {
    .navigation-menu ul {
      /* navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar)
      */
      position: absolute;
      top: 60px;
      left: 0;
      /* stacks the li tags vertically 
      */
      flex-direction: column;
     /*makes menu span full height and width
     */ 
     width: 100%;
      height: calc(100vh - 77px);
      background-color: black;
      border-top: 1px solid black;
    }
    .navigation-menu li {
      /* centers link text and strips off margin
      */
      text-align: center;
      margin: 0;
    }
    .navigation-menu li a {
      color: white;
      /*increases the surface area of the anchor tag to span the full width of the menu
      */
      width: 100%;
      padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
      background-color:greenyellow;
    }
  }
  @media screen and (max-width: 768px) {
    .navigation-menu ul {
        display: none;
    
    }
  }
  @media screen and (max-width: 768px) {
    .navigation {
      background-color:lightblue;
      
    }
  }
  
  @media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
      display: block;
      background-color:forestgreen;
      z-index: 1;
    }
    #img{
      margin-left: -12rem;
      width: 100px;
      height:100px;
      border-radius: 25px;
    }
    

    
  }
  
  .inner{
    margin: 2rem;
  }
  .logo{
    margin-left: 10rem;
  }
  #img{
    width: 400px;
    height:200px;
    border-radius: 25px;
  }